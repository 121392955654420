module.exports = [{
      plugin: require('../node_modules/@pauliescanlon/gatsby-mdx-embed/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":["gatsby-remark-copy-linked-files","gatsby-remark-smartypants","gatsby-remark-autolink-headers",{"resolve":"gatsby-remark-images","options":{"maxWidth":640,"withWebp":true,"tracedSVG":true}},{"resolve":"gatsby-remark-external-links","options":{"target":"_blank","rel":"noopener"}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-fathom/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"TEYXAEOR"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Localhost","short_name":"Localhost","start_url":"/","background_color":"#f7f0eb","theme_color":"#a2466c","display":"standalone","icon":"./static/favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":["gatsby-remark-copy-linked-files","gatsby-remark-smartypants","gatsby-remark-autolink-headers",{"resolve":"gatsby-remark-images","options":{"maxWidth":640,"withWebp":true,"tracedSVG":true}},{"resolve":"gatsby-remark-external-links","options":{"target":"_blank","rel":"noopener"}}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
